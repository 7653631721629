import bgImg from "../pages/Dashboard/CaptureView/Images/bg.png"
import logoImg from "../pages/Dashboard/CaptureView/Images/logo.png"
import emptyLogoImg from "../pages/Dashboard/CaptureView/Images/thumbnailLogo.png"
export const trim_user_name = (name) => {
  if (name?.length < 16) {
    return name;
  } else {
    return `${name.substring(0, 16)}...`;
  }
};

export function convertHMS(value) {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
}
export function convertSeconds(value) {
  let sec = value?.split(":");

  return sec[0] * 3600 + sec[1] * 60 + +sec[2];
}
export function timeToSeconds(timeString) {
  const timeParts = timeString?.split(':')?.map(Number);
  if (timeParts?.length === 3) {
    return timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2];
  } else if (timeParts?.length === 2) {
    return timeParts[0] * 60 + timeParts[1];
  } else {
    return NaN;
  }
}
export function hasAudio(video) {
  return (
    video.mozHasAudio ||
    Boolean(video.webkitAudioDecodedByteCount) ||
    Boolean(video.audioTracks && video.audioTracks?.length)
  );
}

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export const newIntArr = (arr) => {
  var numberArray = [];
  function add(accumulator, a) {
    return accumulator + a;
  }

  let length = arr?.length;
  for (var i = 0; i < length; i++) numberArray.push(parseInt(arr[i]));
  const sum = numberArray.reduce(add, 0);
  return sum;
};

export const timeDifference = (time) => {
  let totalMinutes;
  const sumOfCurrent = newIntArr(
    new Date().toISOString()?.split("T")[0]?.split("-")
  );
  const sumOfComment = newIntArr(time?.split("T")[0]?.split("-"));
  if (sumOfCurrent === sumOfComment) {
    const current_mint = new Date().toISOString()?.split("T")[1]?.split(".")[0];
    const totalSeconds = convertSeconds(current_mint);
    const comment_mint = time?.split("T")[1]?.split(".")[0];
    const commentSeconds = convertSeconds(comment_mint);
    const diff_sec = totalSeconds - commentSeconds;
    totalMinutes = diff_sec < 3600 ? Math.floor(diff_sec / 60) : diff_sec;
  } else {
    var date = new Date(time);
    var date1 = new Date();
    var sec = Math.round(date1.getTime() / 1000 - date.getTime() / 1000);
    return `${Math.floor(sec / (3600 * 24))}d`;
  }

  if (totalMinutes < 3600) {
    return `${totalMinutes}m`;
  } else {
    return `${Math.floor(totalMinutes / 3600)}h`;
  }
};

export const findDuration = (time) => {
  if (time?.split(":")[0] > 0) {
    return time;
  } else {
    return time.substring(3, 8);
  }
};

export const videoDurationFn = (duration) => {
  const str = duration?.substring(0, 2);
  if (str === "00") {
    return duration?.substring(3, 8);
  } else {
    return duration?.substring(0, 8);
  }
};

export function formatDuration(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  if (hours > 0) {
    const formattedHours = String(hours).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

export const linkUrlFn = (item, userName) => {
  if (!item?.linkUrl?.includes("video-sink-optimised")) {
    return `https://dfw25j7e2vqby.cloudfront.net/${userName}/${item.linkUrl?.split("/")[3]?.split(".")[0]
      }`;
  } else {
    return item.linkUrl?.replace(
      "video-sink-optimised.s3.ap-south-1.amazonaws.com",
      "dfw25j7e2vqby.cloudfront.net"
    );
  }
};

export const FindRecordedorUploaded = (vidArr) => {
  let rec = [];
  let upl = [];
  vidArr.find((item) => {
    if (item?.uploadFrom === "extension") {
      rec.push(item);
    } else {
      upl.push(item);
    }
  })
  return { uploaded: upl, recorded: rec }
}

export const timeFormation = (timeFormat) => {
  if (timeFormat?.includes(":")) {
    const timeComponents = timeFormat?.split(":")
    let hours = timeComponents[timeComponents?.length - 3] || 0
    let minutes = timeComponents[timeComponents?.length - 2] || 0
    let seconds = timeComponents[timeComponents?.length - 1]

    // Extract hours, minutes, and seconds


    if (hours > 0) {
      return `${hours} hr${hours === 1 ? '' : 's'}`;
    } else if (minutes > 0) {
      return `${minutes} min${minutes === 1 ? '' : 's'}`;
    } else {
      return `${seconds} sec${seconds === 1 ? '' : 's'}`
    }
  } else {
    return timeFormat
  }


}


export const getTimeFrame = (val) => {

  // function getTimeAgoString(val) {

  // const originalDateString = '2023-05-08T12:15:40.151Z';
  const parsedDate = new Date(val);
  const formattedDateString = parsedDate.toISOString().slice(0, -5) + 'Z';
  const inputDate = new Date(formattedDateString); // Replace with your desired date


  const currentDate = new Date();
  const timeDifference = currentDate - inputDate;
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const weeksDifference = Math.floor(daysDifference / 7);
  const monthDifference = Math.floor(daysDifference / 30)

  if (minutesDifference < 60) {
    return 'recent';
  } else if (minutesDifference < 120) {
    return '1 hr ago';
  } else if (minutesDifference < 24 * 60) {
    return `${Math.floor(minutesDifference / 60)} hrs ago`;
  } else if (daysDifference < 7) {
    return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
  } else if (weeksDifference < 5) {
    return `${weeksDifference} weeks ago`;
  } else {
    return `${monthDifference} month${monthDifference > 1 ? 's' : ''} ago`;
  }
}


export const getCommentTimeFrame = (val) => {

  // function getTimeAgoString(val) {

  // const originalDateString = '2023-05-08T12:15:40.151Z';
  const parsedDate = new Date(val);
  const formattedDateString = parsedDate.toISOString().slice(0, -5) + 'Z';
  const inputDate = new Date(formattedDateString); // Replace with your desired date


  const currentDate = new Date();
  const timeDifference = currentDate - inputDate;
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const weeksDifference = Math.floor(daysDifference / 7);

  if (minutesDifference < 60) {
    return minutesDifference <= 1 ? `now` : `${minutesDifference} mins ago`
  } else if (minutesDifference < 120) {
    return '1 hr ago';
  } else if (minutesDifference < 24 * 60) {
    return `${Math.floor(minutesDifference / 60)} hrs ago`;
  } else if (daysDifference < 7) {
    return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
  } else if (weeksDifference === 1) {
    return '1 week ago';
  } else {
    return `${weeksDifference} weeks ago`;
  }
}

export const diffinMinutes = (time) => {
  var currentDate = new Date();

  // Provided date
  var providedDate = new Date(time);

  // Calculate the time difference in milliseconds
  var timeDifference = currentDate - providedDate;

  // Convert milliseconds to minutes
  var differenceInMinutes = Math.floor(timeDifference / (1000 * 60));
  if (differenceInMinutes < 30) {
    return true
  } else {
    return false
  }
}

export const captureScreenshot = async (value) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = value?.imageUrl;

    image.crossOrigin = "anonymous";
    image.onload = async function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = 1920;
      canvas.height = 1080;

      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      let x = (value?.clickCoordinates?.X * 1920) / image.width;
      let y = (value?.clickCoordinates?.Y * 1080) / image.height;
      drawCircle(ctx, x, y, 20);

      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    image.onerror = () => {
      reject(new Error("Failed to load image"));
    };
  });

  function drawCircle(context, x, y, radius) {
    context.beginPath();
    context.arc(x, y, radius, 0, 2 * Math.PI);
    context.fillStyle = "#F0648C";
    context.strokeStyle = "#F0648C";
    context.lineWidth = 2;
    context.fill();
    context.stroke();
    context.closePath();
  }
};

export const captureBlankPage = async (each) => {
  return new Promise((resolve, reject) => {
    let result = localStorage.getItem("dimensions");
    let obj = JSON.parse(result);
    if (obj?.width !== "") {
      const image = new Image();
      image.src = bgImg;

      image.crossOrigin = "anonymous";
      image.onload = async function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = 1920;
        canvas.height = 1080;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        function drawTextCentered(text) {
          // Set text properties
          ctx.font = "48px Inter";
          ctx.fillStyle = "#FFFFFF";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          // Get the center coordinates of the canvas
          var centerX = canvas.width / 2;
          var centerY = canvas.height / 2;

          // Draw the text in the center
          ctx.fillText(text, centerX, centerY);
        }

        // Call the function with your desired text
        drawTextCentered(
          each?.title > 70
            ? each?.title?.substring(0, 70) + "..."
            : each?.title
        );
        // let x = (clickCoordinates["X"] * 784) / image.width;
        // let y = (clickCoordinates["Y"] * 441) / image.height;
        // drawCircle(ctx, value?.clickCoordinates?.X, value?.clickCoordinates?.Y, 30);
        // ctx.fillStyle = "#FFF"; // Blue color (you can use any valid color)
        // ctx.fillRect(canvas.width - 338, canvas.height - 88, 302, 52); // (x, y, width, height)

        var logo = new Image();
        logo.src = emptyLogoImg; // Replace with the path to your logo image
        logo.crossOrigin = "anonymous";
        var desiredWidth = 302;
        var desiredHeight = 52;
        var textBottom = "Made with Flonnect";
        logo.onload = function () {
          var logoWidth = 200; // Adjust the width as needed
          var logoHeight = 50; // Adjust the height as needed
          var margin = 10; // Adjust the margin as needed

          // Draw the logo
          ctx.drawImage(
            logo,
            canvas.width - 250,
            canvas.height - 120,
            logoWidth,
            logoHeight
          );

          // Set text properties
          // ctx.font = "21px Inter";
          // ctx.fillStyle = "#131317";
          // ctx.textAlign = "left";
          // ctx.textBaseline = "bottom";

          // // Draw the text
          // ctx.fillText(textBottom, canvas.width - 280, canvas.height - 50);
          const dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        };
      };
      image.onerror = () => {
        reject(new Error("Failed to load image"));
      };
    }
  });

  // resolve(dataURL)
};
export const tempalte = (url, each) => {
  return new Promise((resolve, reject) => {
    var canvas1 = document.createElement("canvas");
    var context = canvas1.getContext("2d");
    canvas1.width = 1920;
    canvas1.height = 1080;
    // Create the first image object
    var bgImage = new Image();

    // Set the source of the first image
    bgImage.src = bgImg; // Replace with the path to your background image
    bgImage.crossOrigin = "anonymous";
    // Wait for the background image to load before drawing it on the canvas1
    bgImage.onload = function () {
      // Draw the background image on the canvas1 starting at coordinates (0, 0)
      context.drawImage(bgImage, 0, 0, canvas1.width, canvas1.height);

      context.strokeStyle = "#D7D7D7";
      context.lineWidth = 2;
      context.beginPath();
      context.moveTo(0, canvas1.height - 52);
      context.lineTo(canvas1.width, canvas1.height - 52);
      context.stroke();

      // Add text at the top center
      var textTop =
        each?.title?.length > 70
          ? each?.title?.substring(0, 70) + "..."
          : each?.title;
      context.fillStyle = "#FFFFFF"; // Set the text color to black
      context.font = "42px Inter"; // Set the font size and type
      var textWidthTop = context.measureText(textTop).width;
      var xTop = (canvas1.width - textWidthTop) / 2; // Center the text horizontally
      var yTop = 80; // Set the vertical position
      context.fillText(textTop, xTop, yTop);
      // Create the second image object
      var centerImage = new Image();

      // Set the source of the second image
      centerImage.src = url; // Replace with the path to your center image
      centerImage.crossOrigin = "anonymous";
      // Set the desired width and height for the center image
      var desiredWidth = 1440;
      var desiredHeight = 810;

      // Set the background color for the border
      context.strokeStyle = "#6056A5";
      context.lineWidth = 8; // Set the border width
      context.fillStyle = "#FFF"; // Blue color (you can use any valid color)
      context.fillRect(0, canvas1.height - 52, canvas1.width, 52); // (x, y, width, height)
      // Wait for the center image to load before drawing it at the center
      centerImage.onload = function () {
        // Calculate the position to center the image on the canvas1
        var centerX = (canvas1.width - desiredWidth) / 2;
        var centerY = (canvas1.height - desiredHeight) / 2;

        // Draw the background color border with border radius
        context.lineJoin = "round"; // Set the line join style to create rounded corners
        context.lineWidth = 8; // Set the border width
        context.strokeRect(
          centerX - 4,
          centerY - 4,
          desiredWidth + 8,
          desiredHeight + 8
        );
        // Draw the center image on the canvas1 with the desired width and height
        context.drawImage(
          centerImage,
          centerX,
          centerY,
          desiredWidth,
          desiredHeight
        );

        var logo = new Image();
        logo.src = logoImg; // Replace with the path to your logo image
        logo.crossOrigin = "anonymous";
        var logoWidth = 50; // Set the width of the logo
        var logoHeight = 50; // Set the height of the logo

        // Add text at the bottom center
        var textBottom = "Made with Flonnect";
        context.fillStyle = "#131317"; // Set the text color to black
        context.font = "21px Inter"; // Set the font size and type
        var textWidthBottom = context.measureText(textBottom).width;
        var xBottom = (canvas1.width - textWidthBottom) / 2; // Center the text horizontally
        var yBottom = canvas1.height - 20; // Set the vertical position
        context.fillText(textBottom, xBottom, yBottom);

        logo.onload = function () {
          context.drawImage(logo, xBottom - 35, yBottom - 20, 28, 28);
          const dataURL = canvas1.toDataURL("image/png");
          resolve(dataURL);
        };
      };
    };
  });
};

export const comparator = (a, b) => {
  // Extract the numbers after "video/"
  const regex = /video\/(\d+)_/;
  const numA = Number(a.match(regex)[1]);
  const numB = Number(b.match(regex)[1]);

  // Compare the extracted numbers
  return numA - numB;
};

export function calculatePercentage(totalDuration, markDuration) {
  // Convert duration strings to seconds
  const totalSeconds = convertToSeconds(totalDuration);
  const markSeconds = convertToSeconds(markDuration);

  // Calculate percentage
  const percentage = (markSeconds / totalSeconds) * 100;
  return Math.floor(percentage);
}

function convertToSeconds(duration) {
  const [minutes, seconds] = duration.split(':');
  return parseInt(minutes) * 60 + parseInt(seconds);
}


export function compareArraysOfObjects(oldData, newData) {
  if (oldData.length !== newData.length) {
    return true; // If the lengths are different, something has changed
  }

  for (let i = 0; i < oldData.length; i++) {
    const oldObject = oldData[i];
    const newObject = newData[i];

    // Check if the number of keys in the objects are the same
    if (Object.keys(oldObject).length !== Object.keys(newObject).length) {
      return true;
    }

    // Check each key-value pair in the objects
    for (const key in oldObject) {
      if (oldObject.hasOwnProperty(key) && newObject.hasOwnProperty(key)) {
        if (oldObject[key] !== newObject[key]) {
          return true; // If any value is different, return true
        }
      } else {
        return true; // If keys are not the same, return true
      }
    }
  }

  return false; // If no differences found, return false
}

export function trimTextTo70Characters(text) {
  if (text?.length > 70) {
    return text?.substring(0, 70) + "...";
  }
  return text;
}

export function compareScriptLength(arr1, arr2) {
  // Create a map to store IDs of objects in arr2
  const idMap = new Map();
  for (const obj of arr2) {
    idMap.set(obj.id, true);
  }

  // Filter arr1 based on presence of IDs in arr2
  const filteredArr1 = arr1.filter(obj => idMap.has(obj.id));

  return filteredArr1;
}


export const viewCount = (viewCount) => {
  if (viewCount < 1000) {
    return viewCount?.toString();
  }
  // Check if the number is between 1,000 and 999,999
  else if (viewCount >= 1000 && viewCount < 1000000) {
    return (viewCount / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  // Check if the number is between 1,000,000 and 999,999,999
  else if (viewCount >= 1000000 && viewCount < 1000000000) {
    return (viewCount / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  // Check if the number is 1,000,000,000 or more
  else if (viewCount >= 1000000000) {
    return (viewCount / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  // Default case (should not reach here with above conditions)
  else {
    return viewCount?.toString();
  }
}

export function removeHtmlTags(text) {
  return text?.replace(/<\/?[^>]+(>|$)/g, "");
}

export const gettingMenuPosition = (each) => {
  let menuDirection = {}
  let totalWidth = each?.viewportDimension?.width;
  let clickedPoinX = each?.clickCoordinates?.x - 23;
  let totalHeight = each?.viewportDimension?.height;
  let clickedPoinY = each?.clickCoordinates?.y - 23
  let horizontalValue = 100 - (((totalWidth - clickedPoinX) / totalWidth) * 100);
  let verticalValue = 100 - (((totalHeight - clickedPoinY) / totalHeight) * 100);
  let maxVal = Math.max(horizontalValue, verticalValue)
  if (horizontalValue > 10 && verticalValue > 10) {
    if (maxVal === horizontalValue) {
      let status = clickedPoinX < (totalWidth / 2)
      if (status) {
        menuDirection["horizontal"] = "Right"
      } else {
        menuDirection["horizontal"] = "Left"
      }
    } else if (maxVal === verticalValue) {
      let status = clickedPoinY < (totalHeight / 2)
      if (status) {
        menuDirection["horizontal"] = "Bottom"
      } else {
        menuDirection["horizontal"] = "Top"
      }
    }
  } else {
    let minVal = Math.min(horizontalValue, verticalValue);
    if (minVal === horizontalValue) {
      let status = clickedPoinX < (totalWidth / 2)
      if (status) {
        menuDirection["horizontal"] = "Right"
      } else {
        menuDirection["horizontal"] = "Left"
      }
    } else if (minVal === verticalValue) {
      let status = clickedPoinY < (totalHeight / 2)
      if (status) {
        menuDirection["horizontal"] = "Bottom"
      } else {
        menuDirection["horizontal"] = "Top"
      }
    }
  }

  let menuVerticalPosition = clickedPoinY < (totalHeight / 2)
  if (menuVerticalPosition) {
    menuDirection["vertical"] = "Bottom"
  } else {
    menuDirection["vertical"] = "Top"
  }
  return menuDirection
}
export const gettingMenuPositionCapture = async (each) => {
  let menuDirection = {}
  if (each?.imageUrl) {
    let dimensions = await gettingViewportDimensions(each?.imageUrl)
    let totalWidth = dimensions?.width;
    let clickedPoinX = each?.clickPoints ? each?.clickPoints?.X - 23 : each?.clickCoordinates?.X - 23;
    let totalHeight = dimensions?.height;
    let clickedPoinY = each?.clickPoints ? each?.clickPoints?.Y - 23 : each?.clickCoordinates?.Y - 23;
    let horizontalValue = 100 - (((totalWidth - clickedPoinX) / totalWidth) * 100);
    let verticalValue = 100 - (((totalHeight - clickedPoinY) / totalHeight) * 100);
    let maxVal = Math.max(horizontalValue, verticalValue)
    if (horizontalValue > 10 && verticalValue > 10) {
      if (maxVal === horizontalValue) {
        let status = clickedPoinX < (totalWidth / 2)
        if (status) {
          menuDirection["horizontal"] = "Right"
        } else {
          menuDirection["horizontal"] = "Left"
        }
      } else if (maxVal === verticalValue) {
        let status = clickedPoinY < (totalHeight / 2)
        if (status) {
          menuDirection["horizontal"] = "Bottom"
        } else {
          menuDirection["horizontal"] = "Top"
        }
      }
    } else {
      let minVal = Math.min(horizontalValue, verticalValue);
      if (minVal === horizontalValue) {
        let status = clickedPoinX < (totalWidth / 2)
        if (status) {
          menuDirection["horizontal"] = "Right"
        } else {
          menuDirection["horizontal"] = "Left"
        }
      } else if (minVal === verticalValue) {
        let status = clickedPoinY < (totalHeight / 2)
        if (status) {
          menuDirection["horizontal"] = "Bottom"
        } else {
          menuDirection["horizontal"] = "Top"
        }
      }
    }

    let menuVerticalPosition = clickedPoinY < (totalHeight / 2)
    if (menuVerticalPosition) {
      menuDirection["vertical"] = "Bottom"
    } else {
      menuDirection["vertical"] = "Top"
    }
    return menuDirection
  }
}
export const gettingMenuPositionHorizontal = (each) => {
  let horizontalPosition = ""
  let totalWidth = each?.viewportDimension?.width;
  let clickedPoinX = each?.clickCoordinates?.x - 23;
  let totalHeight = each?.viewportDimension?.height;
  let clickedPoinY = each?.clickCoordinates?.y - 23
  let leftCovered = 100 - (((totalWidth - clickedPoinX) / totalWidth) * 100)
  let verticalValue = 100 - (((totalHeight - clickedPoinY) / totalHeight) * 100);
  let rightCovered = 100 - leftCovered
  let maxValue = Math.max(leftCovered, rightCovered)
  if (maxValue === leftCovered) {
    horizontalPosition = "Left"
  } else {
    horizontalPosition = "Right"
  }

  return horizontalPosition
}
export const gettingMenuPositionHorizontalCapture = async (each) => {
  let horizontalPosition = ""
  if (each?.imageUrl) {
    let dimensions = await gettingViewportDimensions(each?.imageUrl)
    let totalWidth = dimensions?.width;
    let clickedPoinX = each?.clickPoints ? each?.clickPoints?.X - 23 : each?.clickCoordinates?.X - 23;
    let totalHeight = dimensions?.height;
    let clickedPoinY = each?.clickPoints ? each?.clickPoints?.Y - 23 : each?.clickCoordinates?.Y - 23;
    let leftCovered = 100 - (((totalWidth - clickedPoinX) / totalWidth) * 100)
    let verticalValue = 100 - (((totalHeight - clickedPoinY) / totalHeight) * 100);
    let rightCovered = 100 - leftCovered
    let maxValue = Math.max(leftCovered, rightCovered)
    if (maxValue === leftCovered) {
      horizontalPosition = "Left"
    } else {
      horizontalPosition = "Right"
    }
    return horizontalPosition

  }

}


export const getDisableSide = (each) => {

}


export const gettingViewportDimensions = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;

    image.onload = () => {
      const width = image.width;
      const height = image.height;
      resolve({ width, height });
    };

    image.onerror = () => {
      reject(new Error(`Failed to load   
 image: ${url}`));
    };
  });
}